class DataStore {
    constructor() {
        /* scrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.docScroll = '';
        this.win = $(window);
        this.body = $('body');
        this.htmlBody = $('html, body');

        /* window ++++++++++++++++++++++++++++++++++++++++++*/

        this.docWidth = $(document).width();
        this.docHeight = $(document).height();
        this.winHeight = $(window).height();

        /* non editable ++++++++++++++++++++++++++++++++++++++++++*/
        this.endOfResize = false;

        /* browser ++++++++++++++++++++++++++++++++++++++++++*/

        this.isIe = false;
        this.isFirefox = false;
        this.isSafari = false;
        this.isNoIe = false;
        this.touch = false;
        this.touchBreakPoint = 768;

        /* ids ++++++++++++++++++++++++++++++++++++++++++*/
        this.globalIds = JSON.parse(window.wp_urls.globalIds);

        /* fancybox ++++++++++++++++++++++++++++++++++++++++++*/
        this.fancyBoxOnceOpened = false;

        /* smoothscrolling ++++++++++++++++++++++++++++++++++++++++++*/
        this.scrollEventListener = 'wheel';
        this.smoothDisabled = false;

        /* masonry ++++++++++++++++++++++++++++++++++++++++++*/
        this.grid = '';

        /* lazyload ++++++++++++++++++++++++++++++++++++++++++*/
        this.lazyLoadInstance = '';

        /* Map ++++++++++++++++++++++++++++++++++++++++++*/
        this.mapMarkers = [];
        this.map = '';
        this.geojson = {};

        /* Slider ++++++++++++++++++++++++++++++++++++++++++*/
        this.projectSlider = $('.projectSlider');

        /* Parapllax ++++++++++++++++++++++++++++++++++++++++++*/
        this.parallaxWrap = $('[data-parallaxWrap]');
        this.parallaxImg = this.parallaxWrap.length;
        this.parallaxObject = [];
        this.allowScroll = true;

        /* News Data Scroller ++++++++++++++++++++++++++++++++++++++++++*/
        this.newsDateScroller = $('.newsDateScroller');
        this.newsDateScrollerLength = this.newsDateScroller.length;
        if (this.newsDateScrollerLength) {
            this.newsDateScrollerOffsetTop = this.newsDateScroller.offset().top;
        }

        this.newsPosts = $('#newsPosts .item');
        this.newsPostsLength = this.newsPosts.length;
        this.newsPostsObject = [];
        // DataStore End
    }
}

export default (new DataStore());
