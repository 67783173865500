/* eslint-disable no-undef */
import dataStore from './dataStore';

/* Map Box  ++++++++++++++++++++++++++++++++++++++++++*/
export default function mapBox() {
    const token = wp_urls.mapBoxToken;
    const locations = $('.locations__list ul > li');

    dataStore.geojson = {
        type: 'FeatureCollection',
        features: []
    };

    if (token !== '') {
        centerCall(0);
    }

    //
    function centerCall(index) {
        const currentLocation = $(locations[index]);
        const adress = currentLocation.attr('data-adress');

        fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/
${adress}.json?country=de&limit=1&types=postcode%2Cregion%2Cplace%2Caddress&language=de&access_token=
${token}`).then((response) => response.json())
            .then((data) => {
                dataStore.geojson.features.push({
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates: data.features[0].center
                    }
                });

                if (index + 1 === locations.length) {
                    // ########## INIT MAPBOX ##########
                    initMap();
                } else {
                    centerCall(index + 1);
                }
            }).catch((error) => {
            // eslint-disable-next-line no-console
                console.log(error);
            });
    }

    // Init Map Function
    function initMap() {
        mapboxgl.accessToken = token;

        dataStore.map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/herrlich-media/cknob61o83e1u17qq7i9d5tp4',
            center: dataStore.geojson.features[0].geometry.coordinates,
            offset: 300,
            zoom: 12
        });

        dataStore.map.scrollZoom.disable();

        dataStore.geojson.features.forEach((marker) => {
            // create a HTML element for each feature
            const el = document.createElement('div');
            el.className = 'marker';

            // make a marker for each feature and add to the map
            new mapboxgl.Marker(el)
                .setLngLat(marker.geometry.coordinates)
                .addTo(dataStore.map);
        });
    }
}
