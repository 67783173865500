import '@fancyapps/fancybox';
import './plugins/inView';
import Swiper, {
    Navigation,
    Pagination,
    EffectFade,
    Controller
} from 'swiper';
import { gsap, TweenMax, Expo } from 'gsap/all';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import Plyr from 'plyr';

import {
    parallaxObject,
    parallaxScroll
} from './inc/parallaxScroll';

import mapBox from './inc/hmMaps';
//import smoothScroll from './inc/smoothScroll';
import dataStore from './inc/dataStore';
import { herrlichesMenu, mobileSubmenu, hmNavInit } from './inc/header';
import browserDetection from './inc/browserDetection';
import observeIntersection from './inc/observeIntersection';
import scriptLoader from './inc/scriptLoader';
import devAt from './inc/devAt';

Swiper.use([Navigation, Pagination, EffectFade, Controller]);
gsap.registerPlugin(ScrollToPlugin);

const LazyLoad = require('vanilla-lazyload');
const Masonry = require('masonry-layout');
const ImagesLoaded = require('imagesloaded');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    browserDetection();

    /* Herrlich Media Nav Init ++++++++++++++++++++++++++++++++++++++++++*/
    hmNavInit();

    // Smooth Mousewheel Scrolling for Chrome & Safari
    //smoothScroll();

    // get Header Media Size
    bigHeaderMediaSize();

    /* Map Box observe of intersection +++++++++++++++++++++++++++++*/
    function initMap() {
        // load map script and initialize map here
        scriptLoader(
            // eslint-disable-next-line no-undef
            [`${wp_urls.template_dir}/assets/dist/js/mapbox/mapbox-gl.js`],
            mapBox
        );
    }
    observeIntersection('#map', initMap, true);

    /* Jobbase Iframe observe of intersection +++++++++++++++++++++++++++++*/
    const psJobWidget = $('#psJobWidget');
    if (psJobWidget.length) {
        observeIntersection('#psJobWidget', () => {
            scriptLoader(
                // eslint-disable-next-line no-undef
                [psJobWidget.attr('data-jobbasejs')]
            );
        }, true);

        const psJobWidgetUrl = new URL(window.location.href);
        const jh = psJobWidgetUrl.searchParams.get('jh');
        if (jh !== null) {
            $(window).scrollTop(psJobWidget.offset().top);
        }
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Event Listener ++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    // Header Mute Toggle
    let muted = true;
    $('.mute').on('click touch', (e) => {
        muted = !muted;
        $(e.currentTarget).toggleClass('soundOn');
        $(e.currentTarget).siblings('video').prop('muted', muted);
    });

    // Footer Locations
    const locationsListEL = $('#locations .locations__list li');
    let endOfAnimation = true;
    if (locationsListEL.length) {
        locationsListEL.on('click touch', (e) => {
            if (endOfAnimation === true) {
                endOfAnimation = false;
                const listIndex = $(e.currentTarget).index();
                const currentListImg = $('.locations__imgs li').eq(listIndex);

                $('.locations__imgs li').not(currentListImg).removeClass('active');

                dataStore.map.flyTo({
                    center: dataStore.geojson.features[listIndex].geometry.coordinates,
                    essential: true // this animation is considered essential with respect to prefers-reduced-motion
                });
                dataStore.map.fire('flystart');

                locationsListEL.not($(e.currentTarget)).removeClass('active');
                $(e.currentTarget).addClass('active');

                setTimeout(() => {
                    currentListImg.addClass('active');

                    if (dataStore.docWidth < dataStore.touchBreakPoint) {
                        dataStore.htmlBody.animate({
                            scrollTop: $('#map').offset().top - 60
                        }, 300);
                    }
                }, 450);

                setTimeout(() => {
                    endOfAnimation = true;
                }, 2000);
            }
        });
    }

    // Page Line Trigger Scroll
    $('.pageLine__scrollerContainer').on('click touch', () => {
        dataStore.htmlBody.stop().animate({
            scrollTop: $('#serviceTeaser').offset().top
        }, 600);
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Sonstiges +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    // Responsie Video Source - Header Banner
    const headerVideo = $('#headerVideo');
    if (headerVideo.length) {
        const mobilevideo = headerVideo.attr('data-mobilevideo');
        let headerVideoSource = headerVideo.attr('data-desktopvideo');

        if (typeof (mobilevideo) !== 'undefined'
            && mobilevideo.length && mobilevideo !== ''
            && dataStore.docWidth <= dataStore.touchBreakPoint) {
            headerVideoSource = headerVideo.attr('data-mobilevideo');
        }

        headerVideo.find('source').attr('src', headerVideoSource);
        headerVideo.get(0).load();
    }

    // News
    const newsPosts = $('#newsPosts');
    if (newsPosts.length) {
        // News Post Toggle
        let newsPostToggleIndex = 1;
        $('.newsPosts__toggle').on('click touch', (e) => {
            const currentToggle = $(e.currentTarget);
            const currentContent = currentToggle
                .siblings('.newsPosts__container__contentWrapper')
                .find('.newsPosts__container__content');
            const currentParent = currentToggle.parent();

            newsPostToggleIndex += 1;
            currentContent.toggleClass('isActive');
            currentToggle.toggleClass('isActive');
            currentParent.css({
                'z-index': newsPostToggleIndex
            });

            if (currentContent.hasClass('isActive')) {
                currentToggle.attr('data-text', 'weniger anzeigen').find('span').text('weniger anzeigen');
            } else {
                currentToggle.attr('data-text', 'mehr anzeigen').find('span').text('mehr anzeigen');
            }

            dataStore.grid.layout();
        });

        if ($('.newsPosts__container').length === 1) {
            dataStore.newsDateScroller.addClass('left');
        }

        // News More Button
        const newsPostsDefaultHeight = newsPosts.height();
        let newsPostsNewHeight = 1600;
        if (newsPostsDefaultHeight > newsPostsNewHeight) {
            newsPosts.height(newsPostsNewHeight).addClass('loadMoreActive')
                .append('<div class="loadMore"><span class="btn">Mehr Anzeigen</span></div>');
        }

        const newsPostsLoadMore = '#newsPosts .loadMore';
        $(document).on('click touch', newsPostsLoadMore, () => {
            newsPostsNewHeight += 1600;
            if (newsPostsDefaultHeight > newsPostsNewHeight + 1600) {
                newsPosts.animate({
                    height: newsPostsNewHeight
                }, 600);
            } else {
                newsPosts.attr('style', '').removeClass('loadMoreActive');
                $(newsPostsLoadMore).remove();
            }
        });
    }

    // Jobs Filter
    const jobsTagSelectorsSelectors = $('.jobsTagSelectorsContainer .jobsTagSelectors');
    if (jobsTagSelectorsSelectors.length) {
        $('.jobsTagSelectorsAll').on('click touch', function () {
            removeActive();
            $(this).addClass('activeState');
            $('#jobsPostsFilter .container .row .item').each(function() {
                $(this).show(400);
            });
        });

        $('.jobsTagSelectors').on('click touch', function () {
            const filterTrigger = $(this).attr('data-jobstag-selectors');
            removeActive();
            $(this).addClass('activeState');

            $('#jobsPostsFilter .container .row .item').each(function() {
                const filterContent = $(this).attr('data-jobstag-selectors')
                if (filterTrigger === filterContent ) {
                    $(this).show(450);
                } else {
                    $(this).hide(450);
                }
            });
        });

        function removeActive() {
            $('.jobsTagSelectorsContainer').children().each(function() {
                $(this).removeClass('activeState');
            });
        }
    }

    const jobsPostsFilter = $('#jobsPostsFilter');
    if (jobsPostsFilter.length) {
        // jobs Post Toggle
        let jobsPostToggleIndex = 1;
        $('.jobsPosts__toggle').on('click touch', (e) => {
            const currentToggle = $(e.currentTarget);
            const currentContent = currentToggle
                .siblings('.jobsPosts__container__contentWrapper')
                .find('.jobsPosts__container__content');
            const currentParent = currentToggle.parent();

            jobsPostToggleIndex += 1;
            currentContent.toggleClass('isActive');
            currentToggle.toggleClass('isActive');
            currentParent.css({
                'z-index': jobsPostToggleIndex
            });

            if (currentContent.hasClass('isActive')) {
                currentToggle.attr('data-text', 'weniger anzeigen').find('span').text('weniger anzeigen');
            } else {
                currentToggle.attr('data-text', 'mehr anzeigen').find('span').text('mehr anzeigen');
            }
        });


        const jobsPostsLoadMore = '#jobsPostsFilter .loadMore';
        $(document).on('click touch', jobsPostsLoadMore, () => {
            jobsPostsNewHeight += 1600;
            if (jobsPostsDefaultHeight > jobsPostsNewHeight + 1600) {
                jobsPosts.animate({
                    height: jobsPostsNewHeight
                }, 600);
            } else {
                jobsPosts.attr('style', '').removeClass('loadMoreActive');
                $(jobsPostsLoadMore).remove();
            }
        });

        const jobsPostsContainer = $('.jobsPosts__container');
        if (jobsPostsContainer.length) {
            for (let jobsPostsContainerIndex = 0;
                jobsPostsContainerIndex < jobsPostsContainer.length;
                jobsPostsContainerIndex++) {
                const currentContainer = $(jobsPostsContainer[jobsPostsContainerIndex]);
                const contentContent = currentContainer.find('.jobsPosts__container__content');
                currentContainer.removeClass('showToggle');
    
                const contentContentHeight = contentContent.height();
    
                if (contentContentHeight > 54) {
                    currentContainer.addClass('showToggle');
                } else {
                    currentContainer.removeClass('showToggle');
                }
            }
        }
    }

    // Stamp page jump
    const stampPageJump = $('#header-stamp, #header-stamp-mobile');
    const stampPageJumpLocation = stampPageJump.attr('data-stempel-button-hash');
    stampPageJump.on('click touch', () => {
        console.log(stampPageJumpLocation);
        $('body, html').animate({
            scrollTop: $(stampPageJumpLocation).offset().top
        }, 600);
    });

    /*------------------------ Before/After Slider -----------------------*/
    const afterBefore = $('.afterBefore');
    for (let i = 0; i < afterBefore.length; i++) {
        const afterBeforeEl = $(afterBefore[i]);
        const afterBeforeElWidth = afterBeforeEl.width();
        const beforeAfterImgSwitchContainer = afterBeforeEl.find('.afterBefore__switcher');
        const beforeAfterImgSwitch = afterBeforeEl.find('.afterBefore__switcherBtn');
        const beforeImg = afterBeforeEl.find('.afterBefore__image:nth-child(2), picture:last-of-type');

        beforeAfterImgSwitch.on('mousedown touchstart', (e) => {
            e.preventDefault();
            afterBeforeEl.on('mousemove touchmove', (event) => {
                let pageX = 0;

                if (dataStore.touch === true) {
                    pageX = event.originalEvent.touches[0].pageX;
                } else {
                    pageX = event.pageX;
                }

                const mousePostion = pageX - afterBeforeEl.offset().left;
                let mousePostionPercent = (mousePostion / afterBeforeElWidth) * 100;

                if (mousePostionPercent < 0) {
                    mousePostionPercent = 0;
                } else if (mousePostionPercent >= 100) {
                    mousePostionPercent = 100;
                }

                beforeAfterImgSwitchContainer.css({
                    left: `${mousePostionPercent}%`,
                });

                beforeImg.css({
                    'clip-path': `polygon(0 0, ${mousePostionPercent}% 0%, ${mousePostionPercent}% 100%, 0% 100%)`,
                });
            }).on('mouseleave', () => {
                afterBeforeEl.off('mousemove touchmove');
            });
        }).on('mouseup touchend', () => {
            afterBeforeEl.off('mousemove touchmove');
        });
    }

    // Service Selection Event Listener
    const serviceContainer = $('.serviceAreas__container');
    const serviceAreasSelection = $('.serviceAreas__selection > ul > li');
    const serviceAreasSelectionSideNumber = $('.serviceAreas__selection__sideNumber span');
    const serviceAreasSelectionIndicator = $('.serviceAreas__selection__indicator');
    const serviceAreasSelectionIndicatorHeight = serviceAreasSelectionIndicator.height();
    if (serviceContainer.length) {
        let contentHeight = $('.serviceAreas__content.active').outerHeight();
        serviceContainer.animate({
            height: contentHeight,
        }, 150);

        serviceAreasSelection.on('click touch', (e) => {
            e.preventDefault();
            const thisLi = $(e.currentTarget);
            const serviceAreasContent = $('.serviceAreas__content').eq(thisLi.index());
            const serviceVideo = serviceAreasContent.find('.plyr');

            serviceAreasSelection.not(thisLi).removeClass('active');
            thisLi.addClass('active');

            $('.serviceAreas__content.active').removeClass('active');
            serviceAreasContent.addClass('active');

            let liSideNumber = thisLi.index() + 1;
            if (liSideNumber < 10) {
                liSideNumber = `0${liSideNumber}`;
            }

            serviceAreasSelectionIndicator.css({
                transform: `scale(1, ${((thisLi.offset().top - serviceAreasSelectionIndicator.offset().top
                    + thisLi.outerHeight()) / (serviceAreasSelectionIndicatorHeight / 100)) / 100})`
            });
            serviceAreasSelectionSideNumber.text(liSideNumber);

            if (plyr.length) {
                for (let plyrI = 0; plyrI < plyr.length; plyrI++) {
                    plyr[plyrI].pause();
                }
            }

            if (serviceVideo.length) {
                plyr[parseInt(serviceVideo.attr('data-index'), 10)].play();
            }

            contentHeight = $('.serviceAreas__content.active').outerHeight();
            if (dataStore.docWidth < dataStore.touchBreakPoint) {
                serviceContainer.css({
                    height: contentHeight
                });
                setTimeout(() => {
                    dataStore.htmlBody.animate({
                        scrollTop: serviceContainer.offset().top - 60
                    }, 300);
                }, 300);
            } else {
                setTimeout(() => {
                    serviceContainer.animate({
                        height: contentHeight,
                    }, 150);
                }, 300);
            }
        });
    }

    /*--- Scroll to ID on Click ---*/
    $('a[href*="#"]:not([href="#"])').on('click touch', (e) => {
        if (window.location.pathname.replace(/^\//, '') === e.currentTarget.pathname.replace(/^\//, '')
                && window.location.hostname === e.currentTarget.hostname) {
            let hashTarget = $(e.currentTarget.hash);
            hashTarget = hashTarget.length ? hashTarget : $(`[name=${e.currentTarget.hash.slice(1)}]`);
            if (hashTarget.length) {
                $('.open').removeClass('open');
                $('html,body').animate({
                    scrollTop: hashTarget.offset().top - 60
                }, 300);
                e.preventDefault();
            }
        }
    });

    // Add Button Text Attribute
    const allButtons = $('.btn, .menu-item a');
    for (let btnIndex = 0; btnIndex < allButtons.length; btnIndex++) {
        const btnEl = $(allButtons[btnIndex]);
        const btnText = btnEl.text();
        btnEl.attr('data-text', btnText).html(`<span>${btnText}</span>`);
    }

    const logoWallWrapper = $('#logoWallWrapper');
    if (logoWallWrapper.length) {
        const logoWrapActive = $('.logoWrap.active');
        const logoWrapNotActive = $('.logoWrap:not(.active)');

        setInterval(() => {
            const currentActive = $(logoWrapActive[getRandomInt(logoWrapActive.length)]);
            const currentDeactive = $(logoWrapNotActive[getRandomInt(logoWrapNotActive.length)]);
            changeLogoWallImg(currentActive, currentDeactive);
        }, 2000);
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    lazyloadUpdate();

    // FANCYBOX
    $('[data-fancybox]').fancybox({
        afterLoad() {
            dataStore.smoothDisabled = true;
        },
        beforeClose() {
            dataStore.smoothDisabled = false;
        }
    });

    $('a.wiki-image').fancybox();

    /**** PLYR ***/
    const plyr = Array.from(document.querySelectorAll('.plyr')).map((p) => new Plyr(p, {
        controls:
        ['play-large', 'play', 'progress', 'current-time', 'mute',
            'volume', 'captions', 'pip', 'airplay', 'fullscreen']
    }));

    for (let plyri = 0; plyri < plyr.length; plyri++) {
        plyr[plyri].on('ready', (event) => {
            $(event.target).addClass('notClicked vis');
            $(event.target).attr('data-index', plyri);
        });
        plyr[plyri].on('playing', (event) => {
            $(event.target).removeClass('notClicked');
        });
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll(() => {
        dataStore.docScroll = $(window).scrollTop();

        // Parallax Scroll
        parallaxScroll();

        // News Date Scroll
        newsDateScroll();

        // News Into View Scroll
        newsPostsIntoView();
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    $(window).trigger('scroll');

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
    }, 250);
});

function afterResize() {
    mobileSubmenu();
    herrlichesMenu.destroy();
    herrlichesMenu.init();

    // Parallax Object after ajax
    parallaxObject();

    // News Posts Object
    newsPostsIntoViewObject();

    // big Header media 16:9
    bigHeaderMediaSize();

    // News Date Offset
    if (dataStore.newsDateScrollerLength) {
        dataStore.newsDateScrollerOffsetTop = dataStore.newsDateScroller.offset().top;
    }
}

$(window).on('load', () => {
    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* Masonry  ++++++++++++++++++++++++++++++++++++++++++*/
    masonryUpdate();

    /* Swiper  ++++++++++++++++++++++++++++++++++++++++++*/
    if (dataStore.projectSlider.length) {
        // Size Projet Slides
        projectSliderSizer();

        dataStore.projectSlider = new Swiper('.projectSlider', {
            slidesPerView: 'auto',
            spaceBetween: 20,
            autoHeight: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                986: {
                    autoHeight: false,
                    spaceBetween: 40,
                }
            },
            on: {
                init: (e) => {
                    $(e.el).addClass('vis');
                    lazyloadUpdate();
                },
                beforeResize: () => {
                    // Size Projet Slides
                    projectSliderSizer();
                }
            }
        });

        dataStore.projectSlider.on('slideChange', () => {
            lazyloadUpdate();
        });
    }

    new Swiper('.headerSlider', {
        // Optional parameters
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        on: {
            init: (e) => {
                $(e.el).addClass('vis');
            }
        }
    });

    /* In View  ++++++++++++++++++++++++++++++++++++++++++*/
    $('.fadeIn').one('inview', (e, visible) => {
        if (visible === true) {
            $(e.currentTarget).not('.vis').addClass('vis');
            setTimeout(() => {
                $(e.currentTarget).removeClass('fadeIn');
            }, 600);
        }
    });

    $('.fadeInRow').one('inview', (e, visible) => {
        if (visible === true) {
            $(e.currentTarget).find('> div, > li, > p, > span, > a, #header-menu > li').not('.vis').each((i, el) => {
                setTimeout(() => {
                    $(el).addClass('vis');
                }, 150 * i);
            });
        }
    });

    const darkMode = $('.darkMode');
    darkMode.on('inview', (e, visible) => {
        if (visible === true) {
            dataStore.body.addClass('is-dark');
        } else if (dataStore.docScroll <= $(e.currentTarget).offset().top) {
            dataStore.body.removeClass('is-dark');
        }
    });

    const ligthMode = $('.lightMode');
    ligthMode.on('inview', (e, visible) => {
        if (visible === true) {
            dataStore.body.removeClass('is-dark');
        } else if (dataStore.docScroll <= $(e.currentTarget).offset().top) {
            dataStore.body.addClass('is-dark');
        }
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Sonstines +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    // Trigger Scroll on Load
    $(window).trigger('scroll');

    // Check news content Height
    newsPostContentHeight();

    // get Page Line Length
    pageLineLength();

    // News Date Offset
    if (dataStore.newsDateScrollerLength) {
        dataStore.newsDateScrollerOffsetTop = dataStore.newsDateScroller.offset().top;
    }

    // DarkM ode on Load
    if (darkMode.length && dataStore.docScroll >= darkMode.offset().top) {
        dataStore.body.addClass('is-dark');
    }

    /* developed at ++++++++++++++++++++++++++++++++++++++++++*/
    devAt();
});

// Lazyload Update
const lazyloadUpdate = function lazyloadUpdate() {
    if (dataStore.lazyLoadInstance !== '') {
        dataStore.lazyLoadInstance.update();
    }

    // Parallax Object after ajax
    parallaxObject();

    // News Posts Object
    newsPostsIntoViewObject();
};

/* Webp Support Detection  ++++++++++++++++++++++++++++++++++++++++++*/
async function supportsWebp() {
    if (!window.self.createImageBitmap) return false;

    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then((r) => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

// Add Webp to Background Imgaes when supported
(async() => {
    if (await supportsWebp()) {
        dataStore.body.addClass('webp');
        const lazyBg = document.querySelectorAll('[data-bg]');
        lazyBg.forEach((element) => {
            let { bg } = element.dataset;
            if (bg.match(new RegExp('.*(.jpg|.jpeg|.png)')) && !bg.includes('.webp') && !bg.includes('cdninstagram')) {
                bg = `${bg}.webp`;
                element.setAttribute('data-bg', bg);
            }
        });
    }

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy'
    });
    lazyloadUpdate();
})();

function pageLineLength() {
    const pageLine = $('#pageLine');
    if (pageLine.length) {
        const pageLineBottom = (dataStore.docHeight - ($('#map').offset().top + ($('#map').height() / 2) - 65));
        pageLine.attr('style', '');
        pageLine.css({
            bottom: pageLineBottom
        });
    }
}

/* Masonry Wrap  ++++++++++++++++++++++++++++++++++++++++++*/
function masonryUpdate() {
    dataStore.grid = document.querySelector('.masonryWrap');

    if (dataStore.grid !== null) {
        new ImagesLoaded(dataStore.grid, () => {
            dataStore.grid = new Masonry(dataStore.grid, {
                itemSelector: '.item',
                columnWidth: '.item',
                percentPosition: true,
                isOriginLeft: true,
            });
        }).progress(() => {
            dataStore.grid.find('> .item').each((index) => {
                ((that, i) => {
                    setTimeout(() => {
                        $(that).addClass('vis');
                    }, 150 * i);
                })(this, index);
            });

            // News Posts Object
            newsPostsIntoViewObject();
        });
    }
}

/* Checking news content height  ++++++++++++++++++++++++++++++++++++++++++*/
function newsPostContentHeight() {
    const newsPostsContainer = $('.newsPosts__container');
    if (newsPostsContainer.length) {
        for (let newsPostsContainerIndex = 0;
            newsPostsContainerIndex < newsPostsContainer.length;
            newsPostsContainerIndex++) {
            const currentContainer = $(newsPostsContainer[newsPostsContainerIndex]);
            const contentContent = currentContainer.find('.newsPosts__container__content');
            currentContainer.removeClass('showToggle');

            const contentContentHeight = contentContent.height();

            if (contentContentHeight > 54) {
                currentContainer.addClass('showToggle');
            } else {
                currentContainer.removeClass('showToggle');
            }
        }
    }
}

/* News Date Scroller  ++++++++++++++++++++++++++++++++++++++++++*/
let currentScrollAtWindowBottomHalf = '';
let newsDateTranslate = 0;
function newsDateScroll() {
    if (dataStore.newsDateScrollerLength) {
        currentScrollAtWindowBottomHalf = dataStore.docScroll + dataStore.winHeight / 2;
        if (currentScrollAtWindowBottomHalf >= dataStore.newsDateScrollerOffsetTop) {
            newsDateTranslate = currentScrollAtWindowBottomHalf - dataStore.newsDateScrollerOffsetTop;
            TweenMax.to(dataStore.newsDateScroller, 1.1, {
                y: newsDateTranslate,
                ease: Expo.easeOut,
                overwrite: 5
            });
        }
    }
}

/* News Date Scroller  ++++++++++++++++++++++++++++++++++++++++++*/
function getNewsPostDate(item, direction) {
    dataStore.newsDateScroller.find('span').text($(item).find('[data-newsdate]').attr('data-newsdate'));

    if (direction === 'left') {
        dataStore.newsDateScroller.addClass('left');
    } else {
        dataStore.newsDateScroller.removeClass('left');
    }
}

function newsPostsIntoView() {
    if (dataStore.newsPostsLength) {
        for (let a = 0, aLen = dataStore.newsPostsObject.length; a < aLen; a++) {
            if (((dataStore.newsPostsObject[a].elemTop + dataStore.newsPostsObject[a].elemHeight)
                >= dataStore.docScroll + dataStore.winHeight / 2)
                && (dataStore.newsPostsObject[a].elemTop <= (dataStore.docScroll + dataStore.winHeight / 2))) {
                getNewsPostDate(dataStore.newsPostsObject[a].el, dataStore.newsPostsObject[a].direction);
            }
        }
    }
}

function newsPostsIntoViewObject() {
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    //  News Posts Scroller  ++++++++++++++++++++++++++++++++++++++++++*/
    if (dataStore.newsPostsLength) {
        dataStore.newsPostsObject = [];

        dataStore.newsPosts.each((i, el) => {
            const elemHeight = $(el).find('.newsHeadline').height();
            const elemTop = $(el).find('.newsHeadline').offset().top;
            let direction = 'right';

            if ($(el).find('.newsHeadline').offset().left < (dataStore.docWidth / 2)) {
                direction = 'left';
            }

            const tempObject = {
                el: $(el),
                elemHeight,
                elemTop,
                direction
            };
            dataStore.newsPostsObject.push(tempObject);
        });
    }
}

// Random Number
function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

// Logo Change Logo Wall
function changeLogoWallImg(current, next) {
    if (current.length && next.length) {
        const currentHtml = current.html();
        const nextHtml = next.html();
        current.fadeOut(300);
        setTimeout(() => {
            current.empty().append(nextHtml);
            next.empty().append(currentHtml);
            current.fadeIn(300);

            if (dataStore.lazyLoadInstance !== '') {
                dataStore.lazyLoadInstance.update();
            }
        }, 400);
    }
}

// big Header media 16:9 / 10:15
function bigHeaderMediaSize() {
    const bigHeaderMedia = $('.bigHeader__media');
    if (bigHeaderMedia.length && dataStore.docWidth > dataStore.touchBreakPoint) {
        // 16:9
        let bigHeaderMediaWidth = (bigHeaderMedia.height() / 9) * 16;

        // 10:15
        if (bigHeaderMedia.hasClass('slider')) {
            bigHeaderMediaWidth = (bigHeaderMedia.height() / 100) * 150;
        }

        bigHeaderMedia.css({
            width: bigHeaderMediaWidth
        });
    } else if (bigHeaderMedia.length && dataStore.docWidth <= dataStore.touchBreakPoint) {
        bigHeaderMedia.attr('style', '');
    }
    bigHeaderMedia.addClass('mediaIsReady');
}

// Size Projet Slider slides
function projectSliderSizer() {
    const projectSliderSlides = $('.projectSlider .swiper-slide');
    projectSliderSlides.attr('style', '');

    projectSliderSlides.css({
        width: $('.projectSliderSizer').width() - 20
    });
}

// copy link
const copyTooltips = document.querySelectorAll('.copyTooltip');
const copyLinkBtns = document.querySelectorAll('.copyLinkBtn');

copyLinkBtns.forEach((btn, index) => {
    btn.addEventListener('click', function(event) {
        event.preventDefault();

        const tempTextarea = document.createElement('textarea');
        document.body.appendChild(tempTextarea);

        tempTextarea.value = window.location.href;
        tempTextarea.select();

        let wasCopied = false;

        try {
            wasCopied = document.execCommand('copy');
        } catch (err) {
            console.error('Failed to copy link:', err);
        }

        document.body.removeChild(tempTextarea);

        if (wasCopied) {
            copyTooltips[index].textContent = 'Link kopiert';
        } else {
            copyTooltips[index].textContent = 'Link konnte nicht kopiert werden';
        }
        
        copyTooltips[index].style.display = 'block';
        
        setTimeout(function() {
            copyTooltips[index].style.display = 'none';
        }, 1000);
    });
});

// wiki login
const DURATION = 86400000; // 1 day

const content = document.querySelectorAll('.protected-content');
const wikiLoginElement = document.getElementById('wiki-login');
const passwordInput = document.getElementById('passwordInput');
const message = document.getElementById('message');
const wikiHeader = document.getElementById('wikiContent');

if(wikiLoginElement) {
    document.addEventListener('DOMContentLoaded', function() {

        let originalWikiHeaderContent = wikiHeader.innerHTML;
        wikiHeader.innerHTML = '';

        const loginTimestamp = localStorage.getItem('loginTimestamp');
        if (loginTimestamp && (Date.now() - loginTimestamp < DURATION)) {
            showProtectedContent();
        }

        wikiLoginElement.addEventListener('submit', async function(event) { 
            event.preventDefault();

            let password = passwordInput.value;
            let response = await fetch('/wp-json/myplugin/v1/check-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ password: password })
            });

            let result = await response.json();

            if (result) {
                localStorage.setItem('loginTimestamp', Date.now());
                showProtectedContent();
                location.reload();
                message.innerHTML = '';
            } else {
                message.innerHTML = '<p class="wiki-error"><strong>Fehlerhaftes Passwort.</strong><br>Versuche es noch einmal.</p>';
            }
        });

        function showProtectedContent() {
            content.forEach(el => el.style.display = 'block');
            wikiLoginElement.style.display = 'none';
            wikiHeader.innerHTML = originalWikiHeaderContent;

            initializeFiltering();

            setInterval(function() {
                if (Date.now() - localStorage.getItem('loginTimestamp') >= DURATION) {
                    content.forEach(el => el.style.display = 'none');
                    wikiLoginElement.style.display = 'block';
                    message.textContent = 'Ihr Login ist abgelaufen';
                    wikiHeader.innerHTML = '';
                    localStorage.removeItem('loginTimestamp');
                }
            }, 1000);
        }
    });
}

// wiki filter
function initializeFiltering() {
    const wikiFilterBtns = document.querySelectorAll(".wiki-filter-btn");
    const wikiPosts = document.querySelectorAll(".wiki-post");
    const resetBtn = document.getElementById("resetFilter");

    wikiFilterBtns.forEach(button => {
        button.addEventListener("click", function() {
            const category = this.getAttribute('data-category');
            wikiFilterBtns.forEach(btn => btn.classList.remove('active'));
            this.classList.add('active');

            wikiPosts.forEach(post => {
                if (category === 'all' || post.getAttribute('data-categories').includes(category)) {
                    post.classList.remove('hidden');
                } else {
                    post.classList.add('hidden');
                }
            });
        });
    });

    // reset
    if(resetBtn) {
        resetBtn.addEventListener("click", function() {
            wikiFilterBtns.forEach(btn => btn.classList.remove('active'));
            wikiPosts.forEach(post => {
                post.classList.remove('hidden');
            });
        });
    }
}
