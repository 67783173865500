export default function observeIntersection(el, callback, once, thresh, root, rootm) {
    const options = {
        root,
        rootMargin: rootm,
        threshold: thresh,
    };
    const elContainer = document.querySelectorAll(el);
    const elObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                callback();
                if (once) {
                    elObserver.unobserve(entry.target);
                }
            }
        });
    }, options);
    elContainer.forEach((target) => {
        elObserver.observe(target);
    });
}
